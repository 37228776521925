<template>
    <section class="compras-orden-compra px-3">
        <titulo-divisor titulo="Orden de compra">
            <div class="row">
                <div class="col-auto px-1">
                    <el-input v-model="buscarListado" placeholder="Buscar ordenes" size="small" />
                </div>
                <div class="col-auto px-1 router-compras">
                    <router-link :to="{ name:'compras.ordenes.listado' }" class="">
                        <div class="btn-square32 mx-1">
                            <i class="icon-menu" />
                        </div>
                    </router-link>
                    <router-link :to="{ name:'compras.ordenes.semanas' }" class="">
                        <div class="btn-square32 mx-1">
                            <i class="icon-view-column f-23" />
                        </div>
                    </router-link>
                </div>
                <template v-if="isPurchaseOrderList">
                    <div v-if="filtersCount == 0" class="col-auto my-auto px-1 icon-option">
                        <el-tooltip content="Filtro" placement="bottom" effect="light" visible-arrow>
                            <button class="btn btn-square32-5d" @click="abrirModalFiltros">
                                <i class="icon-filter text-white" />
                            </button>
                        </el-tooltip>
                    </div>
                    <div v-if="filtersCount > 0" class="col-auto filtro-contador my-auto px-1">
                        <div class="d-flex border-general br-4">
                            <button class="btn btn-square32-5d cr-pointer" @click="abrirModalFiltros">
                                <i class="icon-filter text-white" />
                            </button>
                            <div class="d-middle px-2">
                                <p class="f-12">{{ filtersCount }}</p>
                                <i class="icon-close-circle f-11 text-muted pl-1 cr-pointer" @click="clearFilter" />
                            </div>
                        </div>
                    </div>
                </template>
                <div class="col-auto  pl-1 d-none">
                    <button
                    class="btn btn-general f-12 px-3"
                    :class="permitAction('compras.orden.compra.crear') ? 'cr-pointer' : 'cr-not-allowed'"
                    @click="functionPermitAction('compras.orden.compra.crear', crearOrden, [])"
                    >
                        Crear orden
                    </button>
                </div>
            </div>
        </titulo-divisor>
        
        <router-view />
        <!-- partials -->
        <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-12 mb-4">
                    <p class="input-label-top">Proyecto</p>
                    <el-select v-model="filters.id_oportunidad" placeholder="Seleccionar proyecto" size="small" class="w-100" clearable>
                        <el-option v-for="(item, index) in select_projects" :key="`proyecto-${index}`" :label="item.nombre" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-12 mb-4">
                    <p class="input-label-top">Proveedores</p>
                    <el-select v-model="filters.proveedores" placeholder="Seleccionar proveedor" size="small" class="w-100" clearable multiple>
                        <el-option v-for="(item, index) in select_providers" :key="`proveedor-${index}`" :label="item.proveedor" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-12 mb-4">
                    <p class="input-label-top">Cliente</p>
                    <el-select v-model="filters.id_cliente" placeholder="Seleccionar cliente" size="small" class="w-100" clearable>
                        <el-option v-for="(item, index) in select_clients" :key="`cliente-${index}`" :label="item.nombre_corto" :value="item.id" />
                    </el-select>
                </div>
                 <div class="col-6 mb-4">
                    <p class="input-label-top">Fecha de inicio</p>
                    <el-date-picker v-model="filters.fecha_inicio" type="date" class="w-100" size="small" placeholder="Seleccionar un día" />
                </div>
                 <div class="col-6 mb-4">
                    <p class="input-label-top">Fecha fecha_fin</p>
                    <el-date-picker v-model="filters.fecha_fin" type="date" class="w-100" size="small" placeholder="Seleccionar un día" />
                </div>
                <div class="col-12 my-3">
                    <p class="input-label-top">Rango de valor</p>
                    <el-slider v-model="rangeValues" range :min="rangeValues[0]" :max="rangeValues[1]" :format-tooltip="formatTooltipRange" />
                </div>
                <div class="col-12 mb-4">
                    <p class="input-label-top">Estado</p>
                    <el-select v-model="filters.estado" placeholder="Seleccionar cliente" size="small" class="w-100" clearable>
                        <el-option v-for="(item, index) in select_states" :key="`state-${index}`" :label="item.name" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-12 mb-4">
                    <p class="input-label-top">Estado de pago</p>
                    <el-select v-model="filters.estado_pago" placeholder="Seleccionar cliente" size="small" class="w-100" clearable>
                        <el-option v-for="(item, index) in select_payment_status" :key="`payment-status-${index}`" :label="item.name" :value="item.id" />
                    </el-select>
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="clearFilter"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="filterPurchaseOrders"> Filtrar </button>
            </div>
        </modal-lateral>
        <modal-crear-orden ref="modalCrearOrden" />
        <modal-crear-orden-compra ref="modalCrearOrdenCompra" />
    </section>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
export default {
    components:{
        modalCrearOrden: () => import('./partials/modalCrearOrden'),
        modalCrearOrdenCompra: () => import('./partials/modalCrearOrdenCompra'),
    },
    data(){
        return{
            actualYear: moment().year(),
            tabsCompras: [
                {
                    titulo:'listado',
                    ruta:"compras.ordenes.listado",
                },
                {
                    titulo:'Semana',
                    ruta:"compras.ordenes.semanas",
                },
            ],
            buscarListado:'',
            options: [],
        }
    },
    computed: {
        ...mapGetters({
            select_projects: 'selects/selects/select_projects',
            select_providers: 'selects/selects/select_providers',
            select_clients: 'selects/selects/select_clients',
            select_states: 'compras/ordenes_compra/select_states',
            select_payment_status: 'compras/ordenes_compra/select_payment_status',
            filters: 'compras/ordenes_compra/filter_list'
        }),
        filtersCount(){
            return this.filters.count()
        },
        rangeValues:{
            get(){
                return [this.filters.valor_inicial, this.filters.valor_final]
            },
            set(val){
                this.filters.valor_final = val[1]
                this.filters.valor_inicial = val[0]

            }
        },
        isPurchaseOrderList(){
            return this.$route.name === 'compras.ordenes.listado'
        },
    },
    watch:{
        "$route.name"(val){
            this.$store.dispatch('navigation/breadcumbs/replaceBreadcumb', {
                position: 2,
                breadcumb: val
            })
        }
    },
    async created(){
        this.$try(() => {
            this.$store.dispatch('navigation/breadcumbs/getBreadcumbs', ['compras.matrix', 'compras.ordenes',this.$route.name])
            this.getStates();
            this.getPaymentStatus();
            this.sync_selects([
                'Action_get_select_providers', 
                'Action_get_select_projects',
                'Action_get_select_clients',
            ]);
        })
    },
    methods:{
        ...mapActions({
            sync_selects: 'selects/selects/sync',
            clearPurchaseOrderFilter: 'compras/ordenes_compra/Action_clear_filter_list',
            getStates: 'compras/ordenes_compra/Action_get_select_states',
            getPaymentStatus: 'compras/ordenes_compra/Action_get_select_payment_status',
            getPurchaseOrderList: 'compras/ordenes_compra/Action_get_purchase_order_list',
        }),
        abrirModalFiltros(){
            this.$refs.abrirModalFiltros.toggle()
        },
        formatTooltipRange(val){
            return this.formatoMoneda(val)
        },
        async filterPurchaseOrders(){
            this.$refs.abrirModalFiltros.toggle()
            if(this.isPurchaseOrderList){
                await this.getPurchaseOrderList({
                    id: this.$route.params.id,
                    payload: this.filters
                });
            }
        },
        async clearFilter(){
            this.clearPurchaseOrderFilter();
            await this.getPurchaseOrderList({
                id: this.$route.params.id,
                payload: this.filters
            });
        },
        crearOrden(){
            this.$refs.modalCrearOrden.toggle()
        }
    }
}
</script>

<style lang="scss" scoped>
.compras-orden-compra{
    .router-compras{
        display: flex;
        .set-btn-square32{
            min-width: 30px;
            max-width: 30px;
            min-height: 30px;
            max-height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
        }
        .router-link-exact-active{
            .btn-square32{
                @extend .set-btn-square32;
                border: 1px solid var(--color-general);
                background: var(--color-general);
                color: #fff;
            }
        }
        .btn-square32{
            @extend .set-btn-square32;
            border: 1px solid var(--color-general);
            color: var(--color-general);
            &:hover{
                color: #fff !important;
                background: var(--color-general);
            }
        }
    }
}
</style>